<template>
	<div class="holder">
		<p>
			<span class="field"><component :is="type + 'field'" :id="id" :value="value" :required="isRequired" :placeholder="placeholder + (isRequired ? ' *' : '')" :autocomplete="autocomplete" @change="changed" @blur="blur" :options='options' /></span>
		</p>
		<p v-if="isvalid === false && showErrors" class="error">
			<span v-for="(message, key) in validation_messages" :key="key">{{ message }}<br /></span>
		</p>
	</div>
</template>
<script>
	import selectfield from './fields/select';
	import textfield from './fields/text';
	import emailfield from './fields/email';
	import passwordfield from './fields/password';
	import creditcardfield from './fields/creditcard';
	import cvvfield from './fields/cvv';
	import expirydatefield from './fields/expirydate';
	import validationMixin from '../mixins/validation.js';

	export default {
		mixins: [validationMixin],
		props: ['id', 'type', 'value', 'validation', 'comparison', 'placeholder', 'showValidation', 'valid', 'autocomplete', 'options' ],
		components: {
			selectfield,
			textfield,
			emailfield,
			passwordfield,
			creditcardfield,
			expirydatefield,
			cvvfield,
		},
		data: function() {
			return {
				initialValue: null,
				isvalid: null,
				validation_messages: ''
			};
		},
		computed: {
			isRequired: function() {
				if (typeof this.validation != 'undefined') {
					return this.validation.required || false;
				}
				return false;
			},
			showErrors: function() {
				if (this.initialValue != this.value || this.showValidation) {
					return true;
				}
				return false;
			},
			validationParameters: function() {
				var params = Object.assign({
					required: false
				}, this.validation);
				if (this.type == 'email') {
					params.function = 'CheckEmail';
				}
				if (this.type == 'creditcard') {
					params.function = 'CheckCreditCard';
				}
				if (this.type == 'expirydate') {
					params.function = 'CheckExpiryDate';
				}
				return params;
			}
		},
		methods: {
			changed: function(newValue) {
				this.$emit('update:valid', this.validate());
				this.$emit('input', newValue);
			},
			blur: function() {
				this.$emit('update:valid', this.validate());
			}
		},
		watch: {
			value: function() {
                this.$emit('update:valid', this.validate());
			}
		},
		mounted: function() {
			this.initialValue = this.value;		
			this.$emit('update:valid', this.validate());
		}
	};
</script>