var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.active == true ? "active" : "" }, [
    _c("div", { staticClass: "grid-x search-holder" }, [
      _c("button", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.active == true,
            expression: "active == true"
          }
        ],
        staticClass: "button close",
        on: {
          click: function($event) {
            _vm.active = false
          }
        }
      }),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.query,
            expression: "query"
          }
        ],
        staticClass: "searchinput",
        attrs: {
          name: "search",
          type: "text",
          placeholder: "Search for products and inspiration",
          "aria-label": "Search Alternative Flooring"
        },
        domProps: { value: _vm.query },
        on: {
          focus: _vm.activate,
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.query = $event.target.value
          }
        }
      })
    ]),
    _vm._v(" "),
    _vm.active ? _c("div", [_c("p", { staticClass: "help" })]) : _vm._e(),
    _vm._v(" "),
    _vm.showresults && _vm.active == true
      ? _c("div", { staticClass: "results" }, [
          _vm.results.products.length == 0 &&
          _vm.results.inspiration.length == 0
            ? _c("div", { staticClass: "grid-x" }, [
                _c("p", [_vm._v("No results found")])
              ])
            : _c("div", { staticClass: "grid-x medium-up-2" }, [
                _vm.results.products.length > 0
                  ? _c(
                      "div",
                      { staticClass: "cell" },
                      [
                        _c("h2", [_vm._v("Products")]),
                        _vm._v(" "),
                        _vm._l(_vm.results.products, function(product, index) {
                          return _c("div", { staticClass: "search-results" }, [
                            _c("p", [
                              _c("a", {
                                attrs: { href: product.uri },
                                domProps: { innerHTML: _vm._s(product.name) }
                              })
                            ])
                          ])
                        }),
                        _vm._v(" "),
                        _vm.results.product_total > 10
                          ? _c(
                              "div",
                              { staticStyle: { "margin-top": "1rem" } },
                              [
                                _c("pagination", {
                                  attrs: {
                                    records: parseInt(
                                      _vm.results.product_total
                                    ),
                                    "per-page": 10,
                                    "container-class": "pagination",
                                    options: { chunk: 5 }
                                  },
                                  on: { paginate: _vm.loadResults },
                                  model: {
                                    value: _vm.product_page,
                                    callback: function($$v) {
                                      _vm.product_page = $$v
                                    },
                                    expression: "product_page"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      2
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.results.inspiration.length > 0
                  ? _c(
                      "div",
                      { staticClass: "cell" },
                      [
                        _c("h2", [_vm._v("Inspiration")]),
                        _vm._v(" "),
                        _vm._l(_vm.results.inspiration, function(
                          inspiration,
                          index
                        ) {
                          return _c("div", { staticClass: "search-results" }, [
                            inspiration.target != ""
                              ? _c("p", [
                                  _c("a", {
                                    attrs: {
                                      href: inspiration.uri,
                                      target: inspiration.target
                                    },
                                    domProps: {
                                      innerHTML: _vm._s(inspiration.name)
                                    }
                                  })
                                ])
                              : _c("p", [
                                  _c("a", {
                                    attrs: { href: inspiration.uri },
                                    domProps: {
                                      innerHTML: _vm._s(inspiration.name)
                                    }
                                  })
                                ])
                          ])
                        })
                      ],
                      2
                    )
                  : _vm._e()
              ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }