var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "select",
    {
      attrs: { id: _vm.id, name: _vm.id, required: _vm.required },
      on: {
        change: _vm.change,
        blur: function($event) {
          return _vm.$emit("blur")
        }
      }
    },
    [
      _c("option", { attrs: { value: "" } }, [_vm._v("Country")]),
      _vm._v(" "),
      _vm._l(_vm.options, function(option, index) {
        return _c(
          "option",
          {
            key: index,
            domProps: {
              value: option.value,
              selected: option.value == _vm.value
            }
          },
          [_vm._v("\n\t\t\t" + _vm._s(option.name) + "\n\t")]
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }