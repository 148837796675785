window.browserUpdate = require('browser-update');

window.$ = window.jQuery = require('jquery');
require('foundation-sites');
require('motion-ui');
require('slick-carousel');

import RendiliCore from './../../../rendili-core/scripts/rendili-core.js';
window.RendiliCore = RendiliCore;
import Vue from 'vue';
window.Vue = Vue;
import Vuex from 'vuex';
window.Vuex = Vuex;
import * as VuexMapFields from 'vuex-map-fields';
window.VuexMapFields = VuexMapFields;

import rendiliDatePicker from './components/date';
Vue.component('datepicker', rendiliDatePicker);

import rendiliFileUpload from './components/fileupload';
Vue.component('filer', rendiliFileUpload);

import vueField from './components/field';
Vue.component('field', vueField);

import Pagination from 'vue-pagination-2';
Vue.component('pagination', Pagination);

import SiteSearch from './components/site-search';
Vue.component('site-search', SiteSearch);

import MegaMenu from './components/mega-menu';
Vue.component('mega-menu', MegaMenu);

import MapResults from './components/map-results';
Vue.component('map-results', MapResults);

import validationMixin from './mixins/validation.js';
window.ValidationMixin = validationMixin;



