var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "grid-container" }, [
      _c(
        "div",
        {
          staticClass: "grid-x align-center",
          staticStyle: { position: "relative" }
        },
        [
          _vm.masterfolder == 189 || _vm.masterfolder == 397
            ? _c("a", { staticClass: "homelink", attrs: { href: "/" } }, [
                _vm._v("Main Site")
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "ul",
            { staticClass: "menu topmenu align-center" },
            _vm._l(_vm.menuitems, function(menuitem, index) {
              return _c("li", [
                _c(
                  "a",
                  {
                    class: { current: menuitem.current },
                    attrs: { href: menuitem.url },
                    on: {
                      mouseover: function($event) {
                        return _vm.showmega($event, index)
                      },
                      mouseout: _vm.cleartimeout,
                      click: function($event) {
                        return _vm.showmega($event, index)
                      }
                    }
                  },
                  [_vm._v(_vm._s(menuitem.title))]
                )
              ])
            }),
            0
          )
        ]
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "grid-container full" },
      [
        _vm.activelink != null
          ? _c("mega-menu-dropdown", {
              on: {
                closemenu: function($event) {
                  return _vm.hidemega($event)
                }
              },
              model: {
                value: _vm.menuitems[_vm.activelink],
                callback: function($$v) {
                  _vm.$set(_vm.menuitems, _vm.activelink, $$v)
                },
                expression: "menuitems[activelink]"
              }
            })
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }