<template>
	<div>
		<a :href="value.link">
			<img :src="value.image" :alt="value.text">
		</a>
		<br>
		<p><a :href="value.link">{{ value.text }}</a></p>
	</div>
</template>
<script>
	export default {
		props: ['value'],
	}
</script>
