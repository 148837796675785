var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("input", {
      attrs: {
        type: "text",
        id: _vm.id,
        required: _vm.required,
        placeholder: _vm.placeholder,
        autocomplete: _vm.autocomplete ? _vm.autocomplete : "cc-number",
        "aria-label": _vm.placeholder
      },
      on: {
        input: function($event) {
          return _vm.sanitise($event.target.value)
        },
        keypress: _vm.isValidKey,
        blur: function($event) {
          return _vm.$emit("blur")
        }
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }