<template>
	<div class="grid-x grid-padding-x">
		<div class="cell">
			
			<div v-if="value.filename == null">
				<input type="file" accept=".png,.jpg,.jpeg,.gif,.pdf" @change="upload">
			</div>
			<label><small>Add a File - Maxiumum of 1MB</small></label>
			<p v-if="error != ''" class="error">{{ error }}</p>
		</div>
	</div>
</template>
<script>
    export default {
		template: '#fileupload-template',
		props: [ 'value' ],
		data: function() {
			return {
				error: ''
			};
		},
		methods: {
			upload: function(e) {
				this.error = '';
				var files = e.target.files || e.dataTransfer.files;
				if (!files.length) {
					return false;
				}

				var extensions = ['png', 'jpg', 'jpeg', 'gif', 'pdf', 'dxf', 'dwg', 'iges', 'stp', 'step', 'sldprt', 'sldasm'];
				var ext_re = /(?:\.([^.]+))?$/;
				var extension = ext_re.exec(files[0].name)[1].toLowerCase();

				if(!extensions.includes(extension)) {
					this.error = 'Not a valid file type';
					$(e.target).val('');
					return false;
				}			

				if(files[0].size > 1000000) {
					this.error = 'The file is too big';
					$(e.target).val('');
					return false;
				}

				var reader = new FileReader();
				var self = this;
				this.value.filename = files[0].name;
				this.value.contentType = files[0].type;
				reader.onload = (e) => {
					self.value.content = e.target.result;
					self.$emit('save-state');
					$(e.target).val('');
				}
				reader.readAsDataURL(files[0]);
			},
			remove: function() {
				this.value.filename = null;
				this.value.contentType = null;
				this.value.content = null;
				this.$emit('save-state');
			}
		}
    };
</script>