var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.value.children.length > 0
    ? _c("div", { staticClass: "megamenu" }, [
        _c(
          "div",
          { class: "grid-container full inner bg " + _vm.value.bgclass },
          [
            _c(
              "div",
              {
                staticClass: "grid-container",
                staticStyle: { position: "relative" }
              },
              [
                _c("button", {
                  staticClass: "button close",
                  attrs: { "aria-label": "close menu" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("closemenu")
                    }
                  }
                }),
                _vm._v(" "),
                _vm.value.template == "icon-list"
                  ? _c(
                      "div",
                      [
                        _c("mega-menu-icon", {
                          on: { setactivelink: function($event) {} },
                          model: {
                            value: _vm.value.children,
                            callback: function($$v) {
                              _vm.$set(_vm.value, "children", $$v)
                            },
                            expression: "value.children"
                          }
                        })
                      ],
                      1
                    )
                  : _c("div", { staticClass: "grid-x grid-padding-x" }, [
                      _c("div", { staticClass: "large-5 cell" }, [
                        _c("span", { staticClass: "megatitle megaheader" }, [
                          _c("a", { attrs: { href: _vm.value.url } }, [
                            _vm._v(_vm._s(_vm.value.title))
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "grid-x grid-padding-x" }, [
                          _c(
                            "div",
                            { staticClass: "large-6 cell" },
                            [
                              _c("mega-menu-item", {
                                attrs: { activelink: _vm.activelink },
                                on: {
                                  setactivelink: function($event) {
                                    _vm.activelink = $event
                                  },
                                  removeactivelink: function($event) {
                                    _vm.activelink = null
                                  }
                                },
                                model: {
                                  value: _vm.value.children,
                                  callback: function($$v) {
                                    _vm.$set(_vm.value, "children", $$v)
                                  },
                                  expression: "value.children"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "large-6 cell" },
                            [
                              _vm.activelink != null
                                ? _c("mega-menu-item", {
                                    on: { setactivelink: function($event) {} },
                                    model: {
                                      value:
                                        _vm.value.children[_vm.activelink]
                                          .grandchildren,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.value.children[_vm.activelink],
                                          "grandchildren",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "value.children[activelink].grandchildren"
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "large-7 cell" }, [
                        _c("span", { staticClass: "megatitle" }, [
                          _vm._v("handy quick links")
                        ]),
                        _vm._v(" "),
                        _vm.activelink != null
                          ? _c(
                              "div",
                              { staticClass: "grid-x grid-padding-x inspire" },
                              _vm._l(
                                _vm.value.children[_vm.activelink].tips,
                                function(tip, index) {
                                  return _c(
                                    "div",
                                    { staticClass: "large-6 xlarge-4 cell" },
                                    [
                                      _c("mega-menu-tip", {
                                        attrs: { value: tip }
                                      })
                                    ],
                                    1
                                  )
                                }
                              ),
                              0
                            )
                          : _c(
                              "div",
                              { staticClass: "grid-x grid-padding-x inspire" },
                              _vm._l(_vm.value.tips, function(tip, index) {
                                return _c(
                                  "div",
                                  { staticClass: "large-6 xlarge-4 cell" },
                                  [
                                    _c("mega-menu-tip", {
                                      attrs: { value: tip }
                                    })
                                  ],
                                  1
                                )
                              }),
                              0
                            )
                      ])
                    ])
              ]
            )
          ]
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }