var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "grid-x grid-padding-x" }, [
    _c("div", { staticClass: "cell" }, [
      _vm.value.filename == null
        ? _c("div", [
            _c("input", {
              attrs: { type: "file", accept: ".png,.jpg,.jpeg,.gif,.pdf" },
              on: { change: _vm.upload }
            })
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _vm.error != ""
        ? _c("p", { staticClass: "error" }, [_vm._v(_vm._s(_vm.error))])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("small", [_vm._v("Add a File - Maxiumum of 1MB")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }