<template>
	<select :id="id" :name="id" :required="required" @change="change" @blur="$emit('blur')" >
		<option value="">Country</option>
		<option v-for="(option, index) in options" 
				:key="index" :value="option.value"
				:selected="option.value == value">
				{{ option.name }}
		</option>
	</select>
</template>	
<script type="text/javascript">
	export default {
		props: ['id', 'value', 'required', 'placeholder', 'autocomplete', 'options' ],
		data: function() {
			return {
			selected: null
			}
		},
		methods: {
			change(e) {
				const selectedCode = e.target.value;
				this.$emit('change', selectedCode);
			}
		}		
	};
</script>
