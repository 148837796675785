var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "grid-x", staticStyle: { padding: "0 6rem" } },
    _vm._l(_vm.value, function(child, index) {
      return _c("div", { staticClass: "medium-4 xlarge-3 cell" }, [
        _c(
          "a",
          {
            staticClass: "qlink",
            attrs: { href: child.url, target: child.target }
          },
          [
            _c("div", [
              _c("img", { attrs: { src: child.img } }),
              _vm._v(" "),
              _c("img", { staticClass: "icon", attrs: { src: child.icon } })
            ]),
            _vm._v(" "),
            _c("span", [_vm._v(_vm._s(child.title))])
          ]
        )
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }