<template>
	<div>
		<input type="text" :id="id" :required="required" @input="sanitise($event.target.value)" @keypress="isValidKey" @blur="$emit('blur')" :placeholder="placeholder" :autocomplete="autocomplete ? autocomplete : 'cc-number'" :aria-label="placeholder" />
	</div>
</template>
<script type="text/javascript">
	export default {
		props: ['id', 'value', 'required', 'placeholder', 'autocomplete'],
		methods: {
			sanitise: function(value) {
				this.$emit('change', value.replace(/\s/g, ''));
			},
			isValidKey: function(event) {
				event = (event) ? event : window.event;
				var charCode = (event.which) ? event.which : event.keyCode;
				if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 32) {
					event.preventDefault();
				} else {
					return true;
				}
			}
		}
	};
</script>