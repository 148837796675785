var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "holder" }, [
    _c("p", [
      _c(
        "span",
        { staticClass: "field" },
        [
          _c(_vm.type + "field", {
            tag: "component",
            attrs: {
              id: _vm.id,
              value: _vm.value,
              required: _vm.isRequired,
              placeholder: _vm.placeholder + (_vm.isRequired ? " *" : ""),
              autocomplete: _vm.autocomplete,
              options: _vm.options
            },
            on: { change: _vm.changed, blur: _vm.blur }
          })
        ],
        1
      )
    ]),
    _vm._v(" "),
    _vm.isvalid === false && _vm.showErrors
      ? _c(
          "p",
          { staticClass: "error" },
          _vm._l(_vm.validation_messages, function(message, key) {
            return _c("span", { key: key }, [_vm._v(_vm._s(message)), _c("br")])
          }),
          0
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }