<template>
	<div>
		<input type="text" :id="id" :required="required" @input="$emit('change', $event.target.value)" @keypress="isValidKey" @blur="$emit('blur')" :placeholder="placeholder" :autocomplete="autocomplete ? autocomplete : 'cc-exp'" :aria-label="placeholder" maxlength="4" />
	</div>
</template>
<script type="text/javascript">
	export default {
		props: ['id', 'value', 'required', 'placeholder', 'autocomplete'],
		methods: {
			isValidKey: function(event) {
				event = (event) ? event : window.event;
				var charCode = (event.which) ? event.which : event.keyCode;
				if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
					event.preventDefault();
				} else {
					return true;
				}
			}
		}
	};
</script>