var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "vertical menu" },
    _vm._l(_vm.value, function(child, index) {
      return _c("li", [
        _c(
          "a",
          {
            class: [
              { current: _vm.activelink == index },
              { parent: child.grandchildren && child.grandchildren.length > 0 }
            ],
            attrs: {
              href:
                child.grandchildren && child.grandchildren.length > 0
                  ? "#"
                  : child.url
            },
            on: {
              mouseover: function($event) {
                return _vm.setactivelink(index)
              },
              mouseout: function($event) {
                return _vm.removeactivelink(index)
              }
            }
          },
          [
            child.icon
              ? _c(
                  "span",
                  { staticStyle: { display: "inline-block", width: "45px" } },
                  [_c("img", { attrs: { src: child.icon, alt: child.title } })]
                )
              : _vm._e(),
            _vm._v("\n            " + _vm._s(child.title) + "\n        ")
          ]
        )
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }