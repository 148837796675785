var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("input", {
      attrs: {
        type: "password",
        id: _vm.id,
        name: _vm.id,
        required: _vm.required,
        placeholder: _vm.placeholder,
        "aria-label": _vm.placeholder
      },
      domProps: { value: _vm.value },
      on: {
        input: function($event) {
          return _vm.$emit("change", $event.target.value)
        },
        blur: function($event) {
          return _vm.$emit("blur")
        }
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }