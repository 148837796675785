<template>
	<div :class="active == true ? 'active' : ''">
		<div class="grid-x search-holder">
			<button v-show="active == true" class="button close" v-on:click="active=false"></button>
			<input class="searchinput" name="search" type="text" placeholder="Search for products and inspiration" v-model="query" @focus="activate" aria-label="Search Alternative Flooring" />
		</div>
		<div v-if="active">
			<p class="help"></p>
		</div>
		<div class="results" v-if="showresults && active == true">
			<div class="grid-x" v-if="results.products.length == 0 && results.inspiration.length == 0">
				<p>No results found</p>
			</div>
			<div v-else class="grid-x medium-up-2">
				<div class="cell" v-if="results.products.length > 0">
					<h2>Products</h2>
					<div class="search-results" v-for="(product, index) in results.products">
						<p><a :href="product.uri" v-html="product.name"></a></p>
					</div>
					<div v-if="results.product_total > 10" style="margin-top: 1rem;">
						<pagination v-model="product_page" :records="parseInt(results.product_total)" :per-page="10" container-class="pagination" :options="{ chunk: 5 }" @paginate="loadResults"></pagination>
					</div>
				</div>
				<div class="cell" v-if="results.inspiration.length > 0">
					<h2>Inspiration</h2>
					<div class="search-results" v-for="(inspiration, index) in results.inspiration">
						<p v-if="inspiration.target != ''"><a :href="inspiration.uri" v-html="inspiration.name" :target="inspiration.target"></a></p>
						<p v-else><a :href="inspiration.uri" v-html="inspiration.name"></a></p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
    export default {
		data: function() {
			return {
				showresults: false,
				results: null,
				query: null,
				active: false,
				product_page: 1,
				inspiration_page: 1
			};
		},
		methods: {
			loadResults: function(product_page, inspiration_page) {
				if (this.timeOut != null) {
					clearTimeout(this.timeOut);
				}
				if (this.query != '') {
					var self = this;
					this.loading = true;
					RendiliCore.Json.PostJsonData({
						url: "/alternative/search", 
						data: {
							query: this.query,
							product_start: (product_page - 1) * 10,
							inspiration_start: (inspiration_page - 1) * 10
						}
					}).then(function(data) {
						self.results = data.results;
						self.showresults = true;
					});
				}
			},
			pageChange: function(newPage) {
				self.loadResults(newPage, self.inspiration_page);		
			},
			activate: function() {
				var self = this;
				this.active = true;
			}
		},
		watch: {
			query: function() {
				var self = this;

				if (this.timeOut != null) {
					clearTimeout(this.timeOut);
				}

				this.timeOut = setTimeout(function() {
					self.product_page = 1;
					self.inspiration_page = 1;
					self.loadResults(self.product_page, self.inspiration_page);
				}, 250);
			},
		}
	}
</script>